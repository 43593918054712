import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.ActivityTab();
  }

  ActivityTab() {
    $('body').on('click', '.tab-item', ({ target }) => {
      const tab = $(target).data('tab');
      const tabItems = $('.tab-item');

      tabItems.removeClass('tab-active');
      $(target).addClass('tab-active');

      $('.tab-content').removeClass('block');
      $(tab).addClass('block');
      $('.appointments-toggle').toggle(tab === '#appointments');
    });
  }

  filterTransaction() {
    const search_term = $('.filter-transaction-by-text');
    const filterTransactionByDate = $('.filter-transaction-by-date');

    const date = filterTransactionByDate.val().split(' - ');
    const startDate = date[0];
    const endDate = date[1] || date[0];
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    fetch('/setting/filter_transaction', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        filter: {
          start_date: startDate,
          end_date: endDate,
          search_term: search_term.val(),
        },
      }),
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });
  }

  clearSearch() {
    $('.filter-transaction-by-text').val('');
    this.filterTransaction();
  }

  downloadTransactions() {
    $('.download_pdf_transactions').submit();
  }
}
