import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['check', 'name', 'submit'];

  toggle() {
    this.checkTarget.checked = !this.checkTarget.checked;
  }

  nameTargetConnected() {
    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    if (this.nameTarget.value.trim() === '') {
      this.submitTarget.disabled = true;
    } else {
      this.submitTarget.disabled = false;
    }
  }

  checkInput() {
    this.toggleSubmitButton();
  }
}
