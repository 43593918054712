import { Turbo } from '@hotwired/turbo-rails';
import { Controller } from '@hotwired/stimulus';
import moment from 'moment-timezone';

export default class extends Controller {
  static targets = ['submit', 'name', 'email', 'notes', 'form', 'bookingForm'];

  connect() {
    this.ActivityTab();
  }

  enableSubmit() {
    const submitButton = this.submitTarget;
    const name = this.nameTarget.value;
    const email = this.emailTarget.value;

    $(submitButton).attr('disabled', name === '' || email === '');
  }

  enableUpdateNote() {
    const submitButton = this.submitTarget;
    const note = this.notesTarget;

    $(submitButton).attr('disabled', note.value === '');
  }

  editNote() {
    const note = this.notesTarget;
    const submitButton = this.submitTarget;

    $(note).attr('disabled', false).focus();
    $(submitButton).attr('disabled', true);
  }

  deleteNote() {
    const note = this.notesTarget;
    const submitButton = this.submitTarget;

    $(note).val('');
    $(note).attr('disabled', false);
    $(submitButton).attr('disabled', true);
    $(this.formTarget).submit();
  }

  cancelNote() {
    const note = this.notesTarget;
    const submitButton = this.submitTarget;

    $(note).attr('disabled', true);
    $(submitButton).attr('disabled', false);
  }

  submitNote() {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');
    const formData = new FormData(this.formTarget);
    const note = $(this.notesTarget).val();
    formData.append('note', note);

    fetch(this.formTarget.action, {
      method: 'PUT',
      body: formData,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken,
      },
    })
      .then((response) => response.text())
      .then(() => {
        $(this.notesTarget).val(note);
        $(this.notesTarget).attr('disabled', true);
        $(this.submitTarget).attr('disabled', false);
      });
  }

  ActivityTab() {
    $('body').on('click', '.tab-item', ({ target }) => {
      const tab = $(target).data('tab');
      const tabItems = $('.tab-item');

      tabItems.removeClass('tab-active');
      $(target).addClass('tab-active');

      $('.tab-content').removeClass('block');
      $(tab).addClass('block');
      $('.appointments-toggle').toggle(tab === '#appointments');
    });
  }

  buildBookingForm(event) {
    event.preventDefault();

    fetch(event.currentTarget.href)
      .then((response) => response.text())
      .then((html) => {
        document.getElementById('booking-form').innerHTML = html;
        document.getElementById('booking-modal').open = true;
        $('#booking-edit').closest('.btn-circle');
        $('.booking-edit').closest('.btn-circle').remove();
      });
  }

  showOnlyToday(event) {
    const isChecked = event.currentTarget.checked;
    const date = isChecked ? moment(new Date()) : null;
    const url = event.currentTarget.dataset.url;
    const urlParams = new URLSearchParams(window.location.search);
    const pageBooking = urlParams.get('page_booking');
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        page_booking: pageBooking,
        filter: {
          date: date,
        },
      }),
    })
      .then((response) => response.text())
      .then((html) => {
        $('.pagination').toggleClass('hidden', isChecked);
        Turbo.renderStreamMessage(html);
      });
  }
}
