import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['isCustomDomain'];

  toggleCustomDomain() {
    if (this.isCustomDomainTarget.checked) {
      $('.subdomain').addClass('hidden');
      $('.customization_domain').removeClass('hidden');
    } else {
      $('.subdomain').removeClass('hidden');
      $('.customization_domain').addClass('hidden');
    }
  }
}
