import jquery from 'jquery';
window.jQuery = window.$ = jquery;

import select2 from 'select2';
select2(window.$);
import 'select2/dist/css/select2.css';

import 'flatpickr';
import 'parsleyjs';
import 'parsleyjs/src/parsley.css';
import '@/utils/parsley';

import 'trix';
import '@rails/actiontext';

import { Turbo } from '@hotwired/turbo-rails';
Turbo.session.drive = false;

import '@/controllers';
import '@/stylesheets/application/index.scss';
