import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['categoryCheckbox'];

  connect() {
    this.setUpForm();
  }

  setUpForm() {
    $('#service_location').select2({ width: '100%' });
  }

  selectAll() {
    const checkAll = document.getElementById('select-all');
    const checkboxes = document.querySelectorAll(
      'input[name="category[service_ids][]"]',
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = checkAll.checked;
    });

    this.countService();
  }

  selectOne() {
    this.countService();
  }

  countService() {
    const checkboxesChecked = document.querySelectorAll(
      'input[name="category[service_ids][]"]:checked',
    );
    const checkboxes = document.querySelectorAll(
      'input[name="category[service_ids][]"]',
    );

    $('.service-number').html(
      `Select all (${checkboxesChecked.length}/${checkboxes.length})`,
    );

    const checkAll = document.getElementById('select-all');
    checkAll.checked = checkboxesChecked.length === checkboxes.length;
  }

  closeModal() {
    document.getElementById('newCategory').close();
  }
}
