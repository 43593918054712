import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  togglePassword(event) {
    const passwordField = $(event.currentTarget).closest('.password-field');
    const passwordInput = passwordField.find('input');
    const eyeIcon = passwordField.find('.eye');
    const eyeSlashIcon = passwordField.find('.eye-slash');
    const isPasswordVisible = passwordInput.attr('type') === 'password';

    passwordInput.attr('type', isPasswordVisible ? 'text' : 'password');
    eyeIcon.toggle(isPasswordVisible);
    eyeSlashIcon.toggle(!isPasswordVisible);
  }
}
