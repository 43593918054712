import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.loadNotifications();
  }

  loadNotifications() {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');

    fetch('/business/notifications/count_notification_unread', {
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((html) => {
        const total_unread = html.unread_notifications;
        total_unread > 0 &&
          $('#notification_unread').html(
            total_unread > 9 ? '9+' : total_unread,
          );
      });
  }
}
