import { Controller } from '@hotwired/stimulus';
import { validatesParsley } from '@/utils/parsley';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = [
    'continueBtn',
    'companyNameInput',
    'companyPhoneInput',
    'calendar',
  ];
  static values = {
    companyNameTouched: Boolean,
    companyPhoneTouched: Boolean,
  };
  connect() {
    this.setupInitValues();
    this.setUpOnboardingForm();
  }
  setupInitValues() {
    this.continueBtnTarget.setAttribute('disabled', true);
    this.companyNameTouchedValue = false;
    this.companyPhoneTouchedValue = false;
  }

  setUpOnboardingForm() {
    this.form = $('.onboarding-form');
    this.selectIndustry();
  }

  selectIndustry() {
    $('body').on('click', '.btn-industry', ({ currentTarget }) => {
      $('.btn-industry').removeClass('selected');

      const industry = $(currentTarget);
      industry.addClass('selected');
      $('.next').attr('disabled', false);
    });
  }

  closeDisconnectModal() {
    document.getElementById('disconnectModal').close();
  }

  validateForm(step = null) {
    let isValid = true;
    switch (step) {
      case 1:
        isValid = validatesParsley(['#company_name', '#company_phone_number']);
        break;
      case 2:
        isValid = $('.onboarding-form')
          .parsley()
          .validate({ group: 'industry' });
        break;
    }

    return isValid;
  }

  nextStep() {
    const currentStep = this.form.find('.active');
    const nextStep = currentStep.next();
    const step = currentStep.data('step');

    if (!this.validateForm(step)) return;

    this.updateNavigationButtons(currentStep, true);
    this.updateProgressBar(step + 1);
    this.updateContinueButton(step + 1);

    currentStep.removeClass('active').addClass('hidden');
    nextStep.addClass('active').removeClass('hidden');
    nextStep.css({ animation: 'slideInRight ease 1.5s' });

    if (nextStep.hasClass('last')) {
      this.form.submit();
      $('.toggle-switch').prepend(
        '<input value="false" autocomplete="off" type="hidden" name="company[sync_calendar]" id="company_sync_calendar">',
      );
    }
  }

  previousStep() {
    const currentStep = this.form.find('.active');
    const prevStep = currentStep.prev();
    const step = prevStep.data('step');

    this.updateNavigationButtons(currentStep, false);
    this.updateProgressBar(step, false);
    this.updateContinueButton(step);

    currentStep.removeClass('active').addClass('hidden');
    prevStep.addClass('active').removeClass('hidden');
    prevStep.css({ animation: 'slideInLeft ease 1.5s' });
  }

  updateContinueButton(step) {
    if (step == 2 && $('.btn-industry.selected').length == 0) {
      $('.next').attr('disabled', true);
    } else {
      $('.next').attr('disabled', false);
    }
  }

  updateProgressBar(step, isNext = true) {
    $('.progress__bar')
      .find('span')
      .html('Step ' + step);

    const currentStep = $('.progress__bar').find('.active');
    currentStep.removeClass('active');

    if (isNext) {
      currentStep.next().addClass('active bg-green-500');
    } else {
      currentStep.removeClass('active bg-green-500').prev().addClass('active');
    }
  }

  updateNavigationButtons(currentStep, isNext = true) {
    const prevBtn = $('.prev');
    const nextBtn = $('.next');
    const doneBtn = $('.done');
    // const skipBtn = $('.skip');

    prevBtn.removeClass('hidden');
    nextBtn.removeClass('hidden');
    doneBtn.addClass('hidden');
    // skipBtn.addClass('hidden');

    if (isNext) {
      const nextStep = currentStep.next();
      if (nextStep.hasClass('last')) {
        nextBtn.addClass('hidden');
        doneBtn.removeClass('hidden');
        // skipBtn.removeClass('hidden');
      }

      return;
    }

    const prevStep = currentStep.prev();
    if (prevStep.hasClass('first')) prevBtn.addClass('hidden');
  }

  validateInput(event) {
    if (event.target.name === 'company[name]')
      this.companyNameTouchedValue = true;
    if (event.target.name === 'company[phone_number]')
      this.companyPhoneTouchedValue = true;

    const touchedAllFields =
      this.companyNameTouchedValue && this.companyPhoneTouchedValue;
    if (!touchedAllFields) return;

    const isValid = validatesParsley([
      '#company_name',
      '#company_phone_number',
    ]);

    if (isValid) return this.continueBtnTarget.removeAttribute('disabled');
    return this.continueBtnTarget.setAttribute('disabled', true);
  }
}
